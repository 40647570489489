//import logo from './logo.svg';
import { useState, useEffect } from 'react'
import logo from './logo.png'
import './App.css';

function App() {
  const [dots, setDots] = useState('.')
  useEffect(() => {
    let i = 0
    const interval = setInterval(() => {
      i++
      console.log({ i })
      if (i === 5) i = 1
      const d = '.'.repeat(i)
      console.log({ d })
      setDots(d)
    }, 500) 
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <span>{dots}</span>
        <img style={{ maxHeight: '30vh', maxWidth: '90vw' }} src={logo} alt="oughtify.com" />
        <span>comming soon...</span>
      </header>
    </div>
  );
}

export default App;
